@header-height: 5.625rem;
@banner-height: 3rem;

@layout-padding-left-right: 1.5rem;
@layout-padding: 0 @layout-padding-left-right;
@layout-max-width: 90rem;
@layout-text-max-width: 60rem;
@layout-hero-heading-text-max-width: 60rem;
@standard-indent: 2rem;

@main-padding-top: 6rem;
@main-padding-bottom: 6rem;
@main-padding: @main-padding-top 0 @main-padding-bottom;

@main-padding-top-mobile: 4rem;
@main-padding-bottom-mobile: 4rem;
@main-padding-mobile: @main-padding-top-mobile 0 @main-padding-bottom-mobile;

@hero-padding-top: calc(6rem + @header-height);
@hero-padding-bottom: 7rem;

@hero-padding-top-mobile: calc(4rem + @header-height);
@hero-padding-bottom-mobile: 5em;

@hero-padding: @hero-padding-top 0 @hero-padding-bottom;
@hero-padding-mobile: @hero-padding-top-mobile 0 @hero-padding-bottom-mobile;

@layer-bottom: 0;
@intermediate-layer: 5;
@layer-background-lines: 10;
@layer-top-1: 20;
@layer-top-2: 30;
@layer-top-header: 100;

@button-border-radius: 0.5rem;
@card-border-radius: 1.5rem;
@card-border-width: 0.5rem;

@card-large-padding: 3.5rem 3.5rem;
@card-medium-padding: 2rem 2rem;
@card-small-padding: 1rem 1.5rem;
@card-small-textarea-padding: 1.5rem 1.5rem;

@card-large-padding-mobile: 2.5rem 1.5rem;
@card-medium-padding-mobile: 1.5rem;
@card-small-padding-mobile: 1rem 1.5rem;
@card-small-textarea-padding-mobile: 1.5rem 1.5rem;

.section-Layout {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: @layout-max-width;
  padding: @layout-padding;
  z-index: @layer-top-1;
  margin: auto;

  @media @mobile {
    align-items: flex-start;
  }
}

.hero-Layout {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;

  > .section-layout {
    > h1 {
      max-width: @layout-hero-heading-text-max-width;
    }
    > h2,
    > h3,
    > p {
      max-width: @layout-text-max-width;
    }
  }

  @media @mobile {
    justify-content: flex-start;
    text-align: left;
  }
}

.hero-backgroundLayout {
  width: 100%;
  background-color: @color-violet-900;
  padding: @hero-padding;
  margin-top: -@header-height;

  @media @mobile {
    padding: @hero-padding-mobile;
  }
}

.hero-backgroundLayoutRequestDemo {
  width: 100%;
  margin-top: -@header-height;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr; /* Divide the container into two equal columns */
  height: auto;
  overflow-y: hidden;

  @media @mobile {
    display: block;
  }
}

.main-Layout {
  padding: @main-padding;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;

  > .section-layout {
    > h1,
    > h2,
    > h3,
    > p {
      max-width: @layout-text-max-width;
    }
  }

  @media @mobile {
    padding: @main-padding-mobile;
    justify-content: flex-start;
    text-align: left;
  }
}

.main-Layout-section {
  padding: 3rem 0 3rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;

  > .section-layout {
    > h1,
    > h2,
    > h3,
    > p {
      max-width: @layout-text-max-width;
    }
  }

  @media @mobile {
    padding: @main-padding-mobile;
    justify-content: flex-start;
    text-align: left;
  }
}

.card-Layout {
  border-radius: @card-border-radius;
  background-color: @color-white;
  position: relative;
  box-shadow: none;
  border: @card-border-width solid @color-white;
  width: 100%;
}

.card-hoverable-Layout {
  &:hover,
  &:focus {
    box-shadow: @box-shadow-active;
    border-color: @color-indigo-100;
    transition: @transition-all;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span {
      color: @color-primary-900;
    }
    svg {
      stroke: @color-primary-900;
    }
  }
}

.card-small-Layout {
  .card-Layout();
  padding: @card-small-padding;

  @media @tablet {
    padding: @card-small-padding-mobile;
  }
}

.card-medium-Layout {
  .card-Layout();
  padding: @card-medium-padding;

  @media @tablet {
    padding: @card-medium-padding-mobile;
  }
}

.card-large-Layout {
  .card-Layout();
  padding: @card-large-padding;

  @media @tablet {
    padding: @card-large-padding-mobile;
  }
}
