@color-black: #000;
@color-white: #fff;
@color-coolGray-50: #f9fafb;
@color-coolGray-100: #f3f4f6;
@color-coolGray-200: #e5e7eb;
@color-coolGray-300: #d1d5db;
@color-coolGray-400: #9ca3af;
@color-coolGray-500: #6b7280;
@color-coolGray-600: #4b5563;
@color-coolGray-700: #374151;
@color-coolGray-800: #1f2937;
@color-coolGray-900: #111827;
@color-red-50: #fef2f2;
@color-red-100: #fee2e2;
@color-red-200: #fecaca;
@color-red-300: #fca5a5;
@color-red-400: #f87171;
@color-red-500: #ef4444;
@color-red-600: #dc2626;
@color-red-700: #b91c1c;
@color-red-800: #991b1b;
@color-red-900: #7f1d1d;
@color-yellow-50: #fefce8;
@color-yellow-100: #fef9c3;
@color-yellow-200: #fef08a;
@color-yellow-300: #fde047;
@color-yellow-400: #facc15;
@color-yellow-500: #eab308;
@color-yellow-600: #ca8a04;
@color-yellow-700: #a16207;
@color-yellow-800: #854d0e;
@color-yellow-900: #713f12;
@color-amber-50: #fffbeb;
@color-amber-100: #fef3c7;
@color-amber-200: #fde68a;
@color-amber-300: #fcd34d;
@color-amber-400: #fbbf24;
@color-amber-500: #f59e0b;
@color-amber-600: #d97706;
@color-amber-700: #b45309;
@color-amber-800: #92400e;
@color-amber-900: #78350f;
@color-orange-50: #fff7ed;
@color-orange-100: #ffedd5;
@color-orange-200: #fed7aa;
@color-orange-300: #fdba74;
@color-orange-400: #fb923c;
@color-orange-500: #f97316;
@color-orange-600: #ea580c;
@color-orange-700: #c2410c;
@color-orange-800: #9a3412;
@color-orange-900: #7c2d12;
@color-teal-50: #effffc;
@color-teal-100: #c2f4ed;
@color-teal-200: #bbfaee;
@color-teal-300: #9af7e6;
@color-teal-400: #78f5de;
@color-teal-500: #71e5d0;
@color-teal-600: #6ad8c4;
@color-teal-700: #64ccb9;
@color-teal-800: #5fc0af;
@color-teal-900: #4f9e90;
@color-teal-1000: #477169;
@color-blue-25: #f1f1f7;
@color-blue-50: #e8e7f1;
@color-blue-100: #dddced;
@color-blue-200: #c7c3e4;
@color-blue-300: #b2abdb;
@color-blue-400: #9b92d2;
@color-blue-500: #8479c7;
@color-blue-600: #6f62be;
@color-blue-700: #594ab6;
@color-blue-800: #4331ac;
@color-blue-900: #17009a;
@color-blue-1000: #10006c;
@color-indigo-50: #eef2ff;
@color-indigo-100: #e0e8ff;
@color-indigo-200: #c8d3fe;
@color-indigo-300: #a6b4fc;
@color-indigo-400: #828df8;
@color-indigo-500: #6467f2;
@color-indigo-600: #5048e5;
@color-indigo-700: #463acb;
@color-indigo-800: #372fa2;
@color-indigo-900: #312e7f;
@color-purple-50: #faf5ff;
@color-purple-100: #f3e8ff;
@color-purple-200: #e9d5ff;
@color-purple-300: #d8b4fe;
@color-purple-400: #c084fc;
@color-purple-500: #a855f7;
@color-purple-600: #9333ea;
@color-purple-700: #7e22ce;
@color-purple-800: #6b21a8;
@color-purple-900: #581c87;
@color-pink-50: #f2e8ee;
@color-pink-100: #f1dfe7;
@color-pink-200: #edc9d7;
@color-pink-300: #ecb3c9;
@color-pink-400: #e99db9;
@color-pink-500: #e587a8;
@color-pink-600: #e37399;
@color-pink-700: #e15e8a;
@color-pink-800: #df477b;
@color-pink-900: #d91c5c;
@color-pink-1000: #b7184e;
@color-violet-50: #e8e7ed;
@color-violet-100: #d1cedb;
@color-violet-200: #b9b6ca;
@color-violet-300: #a29db8;
@color-violet-400: #8b85a6;
@color-violet-500: #746c94;
@color-violet-600: #5d5482;
@color-violet-700: #453b71;
@color-violet-800: #2e225f;
@color-violet-900: #170a4d;
@color-violet-1000: #0f0632;

@color-primary-25: @color-blue-25;
@color-primary-50: @color-blue-50;
@color-primary-100: @color-blue-100;
@color-primary-200: @color-blue-200;
@color-primary-300: @color-blue-300;
@color-primary-400: @color-blue-400;
@color-primary-500: @color-blue-500;
@color-primary-600: @color-blue-600;
@color-primary-700: @color-blue-700;
@color-primary-800: @color-blue-800;
@color-primary-900: @color-blue-900;
@color-primary-1000: @color-blue-1000;

@color-secondary-50: @color-teal-50;
@color-secondary-100: @color-teal-100;
@color-secondary-200: @color-teal-200;
@color-secondary-300: @color-teal-300;
@color-secondary-400: @color-teal-400;
@color-secondary-500: @color-teal-500;
@color-secondary-600: @color-teal-600;
@color-secondary-700: @color-teal-700;
@color-secondary-800: @color-teal-800;
@color-secondary-900: @color-teal-900;
@color-secondary-1000: @color-teal-1000;

@color-tertiary-50: @color-pink-50;
@color-tertiary-100: @color-pink-100;
@color-tertiary-200: @color-pink-200;
@color-tertiary-300: @color-pink-300;
@color-tertiary-400: @color-pink-400;
@color-tertiary-500: @color-pink-500;
@color-tertiary-600: @color-pink-600;
@color-tertiary-700: @color-pink-700;
@color-tertiary-800: @color-pink-800;
@color-tertiary-900: @color-pink-900;
@color-tertiary-1000: @color-pink-1000;

@color-outlineColor: @color-indigo-300;
@color-indigoOutlineColor: @color-indigo-300;

@color-platformBackground: #fcfdfd;
@color-platformBackgroundDarkened: #f1f1f7;

:root {
  --black: @color-black;
  --white: @color-white;
  --coolGray-50: @color-coolGray-50;
  --coolGray-100: @color-coolGray-100;
  --coolGray-200: @color-coolGray-200;
  --coolGray-300: @color-coolGray-300;
  --coolGray-400: @color-coolGray-400;
  --coolGray-500: @color-coolGray-500;
  --coolGray-600: @color-coolGray-600;
  --coolGray-700: @color-coolGray-700;
  --coolGray-800: @color-coolGray-800;
  --coolGray-900: @color-coolGray-900;
  --red-50: @color-red-50;
  --red-100: @color-red-100;
  --red-200: @color-red-200;
  --red-300: @color-red-300;
  --red-400: @color-red-400;
  --red-500: @color-red-500;
  --red-600: @color-red-600;
  --red-700: @color-red-700;
  --red-800: @color-red-800;
  --red-900: @color-red-900;
  --yellow-50: @color-yellow-50;
  --yellow-100: @color-yellow-100;
  --yellow-200: @color-yellow-200;
  --yellow-300: @color-yellow-300;
  --yellow-400: @color-yellow-400;
  --yellow-500: @color-yellow-500;
  --yellow-600: @color-yellow-600;
  --yellow-700: @color-yellow-700;
  --yellow-800: @color-yellow-800;
  --yellow-900: @color-yellow-900;
  --amber-50: @color-amber-50;
  --amber-100: @color-amber-100;
  --amber-200: @color-amber-200;
  --amber-300: @color-amber-300;
  --amber-400: @color-amber-400;
  --amber-500: @color-amber-500;
  --amber-600: @color-amber-600;
  --amber-700: @color-amber-700;
  --amber-800: @color-amber-800;
  --amber-900: @color-amber-900;
  --orange-50: @color-orange-50;
  --orange-100: @color-orange-100;
  --orange-200: @color-orange-200;
  --orange-300: @color-orange-300;
  --orange-400: @color-orange-400;
  --orange-500: @color-orange-500;
  --orange-600: @color-orange-600;
  --orange-700: @color-orange-700;
  --orange-800: @color-orange-800;
  --orange-900: @color-orange-900;
  --teal-50: @color-teal-50;
  --teal-100: @color-teal-100;
  --teal-200: @color-teal-200;
  --teal-300: @color-teal-300;
  --teal-400: @color-teal-400;
  --teal-500: @color-teal-500;
  --teal-600: @color-teal-600;
  --teal-700: @color-teal-700;
  --teal-800: @color-teal-800;
  --teal-900: @color-teal-900;
  --teal-1000: @color-teal-1000;
  --blue-25: @color-blue-25;
  --blue-50: @color-blue-50;
  --blue-100: @color-blue-100;
  --blue-200: @color-blue-200;
  --blue-300: @color-blue-300;
  --blue-400: @color-blue-400;
  --blue-500: @color-blue-500;
  --blue-600: @color-blue-600;
  --blue-700: @color-blue-700;
  --blue-800: @color-blue-800;
  --blue-900: @color-blue-900;
  --blue-1000: @color-blue-1000;
  --indigo-50: @color-indigo-50;
  --indigo-100: @color-indigo-100;
  --indigo-200: @color-indigo-200;
  --indigo-300: @color-indigo-300;
  --indigo-400: @color-indigo-400;
  --indigo-500: @color-indigo-500;
  --indigo-600: @color-indigo-600;
  --indigo-700: @color-indigo-700;
  --indigo-800: @color-indigo-800;
  --indigo-900: @color-indigo-900;
  --purple-50: @color-purple-50;
  --purple-100: @color-purple-100;
  --purple-200: @color-purple-200;
  --purple-300: @color-purple-300;
  --purple-400: @color-purple-400;
  --purple-500: @color-purple-500;
  --purple-600: @color-purple-600;
  --purple-700: @color-purple-700;
  --purple-800: @color-purple-800;
  --purple-900: @color-purple-900;
  --pink-50: @color-pink-50;
  --pink-100: @color-pink-100;
  --pink-200: @color-pink-200;
  --pink-300: @color-pink-300;
  --pink-400: @color-pink-400;
  --pink-500: @color-pink-500;
  --pink-600: @color-pink-600;
  --pink-700: @color-pink-700;
  --pink-800: @color-pink-800;
  --pink-900: @color-pink-900;
  --pink-1000: @color-pink-1000;
  --violet-50: @color-violet-50;
  --violet-100: @color-violet-100;
  --violet-200: @color-violet-200;
  --violet-300: @color-violet-300;
  --violet-400: @color-violet-400;
  --violet-500: @color-violet-500;
  --violet-600: @color-violet-600;
  --violet-700: @color-violet-700;
  --violet-800: @color-violet-800;
  --violet-900: @color-violet-900;
  --violet-1000: @color-violet-1000;

  --primary-25: @color-primary-25;
  --primary-50: @color-primary-50;
  --primary-100: @color-primary-100;
  --primary-200: @color-primary-200;
  --primary-300: @color-primary-300;
  --primary-400: @color-primary-400;
  --primary-500: @color-primary-500;
  --primary-600: @color-primary-600;
  --primary-700: @color-primary-700;
  --primary-800: @color-primary-800;
  --primary-900: @color-primary-900;
  --primary-1000: @color-primary-1000;
  --secondary-50: @color-secondary-50;
  --secondary-100: @color-secondary-100;
  --secondary-200: @color-secondary-200;
  --secondary-300: @color-secondary-300;
  --secondary-400: @color-secondary-400;
  --secondary-500: @color-secondary-500;
  --secondary-600: @color-secondary-600;
  --secondary-700: @color-secondary-700;
  --secondary-800: @color-secondary-800;
  --secondary-900: @color-secondary-900;
  --secondary-1000: @color-secondary-1000;

  --tertiary-50: @color-tertiary-50;
  --tertiary-100: @color-tertiary-100;
  --tertiary-200: @color-tertiary-200;
  --tertiary-300: @color-tertiary-300;
  --tertiary-400: @color-tertiary-400;
  --tertiary-500: @color-tertiary-500;
  --tertiary-600: @color-tertiary-600;
  --tertiary-700: @color-tertiary-700;
  --tertiary-800: @color-tertiary-800;
  --tertiary-900: @color-tertiary-900;
  --tertiary-1000: @color-tertiary-1000;

  --outlineColor: @color-outlineColor;
  --indigoOutlineColor: @color-indigoOutlineColor;
  --platformBackground: @color-platformBackground;
  --platformBackgroundDarkened: @color-platformBackgroundDarkened;
}

.gradient-primary-700 {
  background-color: hsla(248, 42%, 50%, 1);
  background-image: radial-gradient(at 22% 27%, hsla(251, 31%, 33%, 1) 0px, transparent 50%),
    radial-gradient(at 7% 45%, hsla(248, 42%, 50%, 1) 0px, transparent 50%),
    radial-gradient(at 78% 83%, hsla(251, 31%, 33%, 1) 0px, transparent 50%),
    radial-gradient(at 90% 3%, hsla(240, 100%, 30%, 0.46) 0px, transparent 50%),
    radial-gradient(at 40% 87%, hsla(240, 100%, 30%, 0.54) 0px, transparent 50%);
}

.gradient-primary-900 {
  background-color: hsla(248, 100%, 30%, 1);
  background-image: radial-gradient(at 87% 95%, hsla(246, 31%, 33%, 0.36) 0px, transparent 50%),
    radial-gradient(at 50% 16%, hsla(169, 32%, 46%, 0.27) 0px, transparent 50%),
    radial-gradient(at 12% 91%, hsla(240, 39%, 50%, 0.68) 0px, transparent 50%),
    radial-gradient(at 72% 50%, hsla(240, 94%, 34%, 1) 0px, transparent 50%);
}

.gradient-pink-1000 {
  background-color: hsla(339, 76%, 40%, 1);
  background-image: radial-gradient(at 40% 20%, hsla(167, 85%, 71%, 0.17) 0px, transparent 50%),
    radial-gradient(at 80% 0%, hsla(339, 82%, 31%, 1) 0px, transparent 50%),
    radial-gradient(at 80% 100%, hsla(240, 39%, 69%, 0.34) 0px, transparent 50%),
    radial-gradient(at 0% 0%, hsla(339, 70%, 57%, 1) 0px, transparent 50%);
}

.gradient-violet-800 {
  background-color: @color-violet-800;
  background-image: radial-gradient(at 40% 20%, hsla(251, 31%, 33%, 1) 0px, transparent 50%),
    radial-gradient(at 80% 0%, hsla(169, 32%, 46%, 0.46) 0px, transparent 50%),
    radial-gradient(at 7% 78%, hsla(248, 42%, 50%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 0%, hsla(245, 100%, 30%, 0.73) 0px, transparent 50%);
}

.gradient-violet-900 {
  background-color: hsla(251, 76%, 17%, 1);
  background-image: radial-gradient(at 79% 40%, hsla(251, 47%, 25%, 1) 0px, transparent 50%),
    radial-gradient(at 82% 97%, hsla(252, 78%, 10%, 1) 0px, transparent 50%),
    radial-gradient(at 90% 3%, hsla(240, 100%, 30%, 1) 0px, transparent 50%),
    radial-gradient(at 16% 58%, hsla(240, 100%, 30%, 0.55) 0px, transparent 50%),
    radial-gradient(at 80% 20%, hsla(169, 32%, 46%, 0.75) 0px, transparent 50%);
}

.gradient-violet-1000 {
  background-color: hsla(246, 80%, 6%, 1);
  background-image: radial-gradient(at 22% 27%, hsla(240, 33%, 1%, 0.57) 0px, transparent 50%),
    radial-gradient(at 7% 45%, hsla(240, 47%, 25%, 0.24) 0px, transparent 50%),
    radial-gradient(at 78% 83%, hsla(252, 78%, 10%, 0.75) 0px, transparent 50%),
    radial-gradient(at 90% 3%, hsla(240, 100%, 30%, 0.46) 0px, transparent 50%),
    radial-gradient(at 40% 87%, hsla(240, 100%, 30%, 0.54) 0px, transparent 50%),
    radial-gradient(at 50% 10%, hsla(169, 32%, 46%, 0.25) 0px, transparent 50%);
}

.gradient-teal-1000 {
  background-color: hsla(168, 22%, 36%, 1);
  background-image: radial-gradient(at 40% 30%, hsla(167, 28%, 50%, 0.77) 0px, transparent 50%),
    radial-gradient(at 21% 94%, hsla(243, 54%, 43%, 0.35) 0px, transparent 50%),
    radial-gradient(at 24% 29%, hsla(245, 58%, 56%, 0.46) 0px, transparent 50%);
}
