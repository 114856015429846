@import (reference) "../../styles/main";

@colorSet: {
  black: @color-black;
  white: @color-white;
  coolgray_50: @color-coolGray-50;
  coolgray_100: @color-coolGray-100;
  coolgray_200: @color-coolGray-200;
  coolgray_300: @color-coolGray-300;
  coolgray_400: @color-coolGray-400;
  coolgray_500: @color-coolGray-500;
  coolgray_600: @color-coolGray-600;
  coolgray_700: @color-coolGray-700;
  coolgray_800: @color-coolGray-800;
  coolgray_900: @color-coolGray-900;
  teal_50: @color-teal-50;
  teal_100: @color-teal-100;
  teal_200: @color-teal-200;
  teal_300: @color-teal-300;
  teal_400: @color-teal-400;
  teal_500: @color-teal-500;
  teal_600: @color-teal-600;
  teal_700: @color-teal-700;
  teal_800: @color-teal-800;
  teal_900: @color-teal-900;
  teal_1000: @color-teal-1000;
  blue_25: @color-blue-25;
  blue_50: @color-blue-50;
  blue_100: @color-blue-100;
  blue_200: @color-blue-200;
  blue_300: @color-blue-300;
  blue_400: @color-blue-400;
  blue_500: @color-blue-500;
  blue_600: @color-blue-600;
  blue_700: @color-blue-700;
  blue_800: @color-blue-800;
  blue_900: @color-blue-900;
  blue_1000: @color-blue-1000;
  pink_50: @color-pink-50;
  pink_100: @color-pink-100;
  pink_200: @color-pink-200;
  pink_300: @color-pink-300;
  pink_400: @color-pink-400;
  pink_500: @color-pink-500;
  pink_600: @color-pink-600;
  pink_700: @color-pink-700;
  pink_800: @color-pink-800;
  pink_900: @color-pink-900;
  pink_1000: @color-pink-1000;
  violet_50: @color-violet-50;
  violet_100: @color-violet-100;
  violet_200: @color-violet-200;
  violet_300: @color-violet-300;
  violet_400: @color-violet-400;
  violet_500: @color-violet-500;
  violet_600: @color-violet-600;
  violet_700: @color-violet-700;
  violet_800: @color-violet-800;
  violet_900: @color-violet-900;
  violet_1000: @color-violet-1000;
};
.HeroContainer {
  .hero-Layout();
}

.HeroColWrapper {
  .section-Layout();

  .HeroCol {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 100%;
  }
}

.Hero {
  .hero-backgroundLayout();
  position: relative;

  &.violet_1000 {
    .gradient-violet-1000();
  }
  &.violet_900 {
    .gradient-violet-900();
  }
  &.violet_800 {
    .gradient-violet-800();
  }
  &.primary_900,
  &.blue_700 {
    .gradient-primary-900();
  }
  &.primary_700,
  &.blue_700 {
    .gradient-primary-700();
  }

  &.RemoveTopPadding {
    padding-top: 0;
  }
  &.RemoveBottomPadding {
    padding-bottom: 0;
  }

  &.DoubledPadding {
    padding-bottom: @hero-padding-bottom * 2;
    @media @mobile {
      padding-bottom: @hero-padding-bottom-mobile * 2;
    }
  }

  &.TypeTwoCol {
    .HeroColWrapper {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: flex-start;
      width: 100%;
      gap: 6rem;

      .HeroCol {
        flex: 1 1 0px;
        align-items: flex-start;

        + .HeroCol {
          flex: 0 0 36rem;
        }
      }

      .HeroTitle,
      .HeroSubtitle {
        text-align: left;
        min-width: unset;
        max-width: unset;
      }

      .HeroImage {
        margin: 0;
      }
      @media @tablet-landscape {
        flex-direction: column;
        gap: 3rem;

        .HeroImage {
          max-width: 75%;
        }
      }
    }

    &.TypeTwoColForm {
      .HeroColWrapper {
        .HeroCol {
          flex: 1 1 auto;

          &:last-child {
            flex: 0 0 32rem;
          }
        }
      }
    }
  }

  &.TypeTwoColText {
    .HeroColWrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: row;
      width: 100%;
      gap: 6rem;

      .HeroCol {
        min-width: 28rem;

        @media @tablet-landscape {
          min-width: unset;
        }
      }

      .HeroTitle,
      .HeroSubtitle {
        margin: 0 !important;
        text-align: left;
        max-width: unset;
      }

      .HeroSubtitle {
        .text-subheading-1();
        color: @color-violet-100;
      }

      @media @tablet-landscape {
        flex-direction: column;
        gap: 4rem;
      }
    }
  }
}

.HeroTitle {
  .text-heading-1();
  color: @color-white;
  max-width: @layout-hero-heading-text-max-width;
  margin: 0;
  > span {
    display: flex;
    flex-direction: column;

    @media @tablet-landscape {
      display: inline;
    }
  }
}

.HeroSubtitle {
  .text-subheading-2();
  max-width: @layout-hero-heading-text-max-width;
  color: @color-violet-100;

  &.NoMarginBottom {
    margin-bottom: 0;
  }
}

.HeroParagraph {
  .text-paragraph();
  color: @color-violet-100;
  max-width: @layout-text-max-width;
  margin-top: 2.5rem;
  display: inline-flex;
  gap: 1.5rem;

  @media @tablet {
    flex-direction: column;
    gap: 1rem;
  }

  @media @mobile {
    display: flex;
    width: 100%;
    align-items: flex-start;
  }

  > span {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    svg {
      color: @color-violet-400;
    }
  }
  &.HeroSubheading {
    .text-subheading-2();
    margin-top: 2rem;
    color: @color-white;
  }
}

.HeroImage {
  margin-top: 4rem;
}

.HeroImageWrapper {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  .HeroImage {
    margin-top: 0;
  }
}

.HeroBadges {
  margin-top: 4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 0.5rem;
}

.HeroVideo {
  border-radius: @card-border-radius;
  margin-top: 4rem;
  width: 100%;
  max-width: 60rem;
  box-shadow: @box-shadow-active;
  position: relative;

  .HeroVideoPlaceholderImage {
    z-index: 0;
    pointer-events: none;
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: 0.15;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: @card-border-radius;
    overflow: hidden;

    > * {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .HeroVideoPlaceholder {
    width: 100%;
    border-radius: @card-border-radius;

    background: linear-gradient(
      to bottom,
      rgba(@color-black, 0.5) 0%,
      rgba(@color-black, 1) 50%,
      @color-black 50.01%
    );
    border: 1px solid @color-coolGray-900;
    aspect-ratio: 16 / 9;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > button {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      color: @color-white;
      background: transparent;
      cursor: pointer;
      font-size: @text-heading-1-font-size;
      transition: @transition-all;
      border-radius: @card-border-radius;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      &:hover {
        color: @color-teal-400;
        background-color: @color-black;
        span {
          color: @color-teal-400;
        }
      }
      span {
        .button-text();
        transition: @transition-all;
        color: @color-white;
      }
    }
  }
  .HeroVideoPlayer {
    border-radius: @card-border-radius;
    position: relative;
    outline: none;
    object-position: 0 50%;
    width: 100%;
  }
}

@min: 1;
@max: 3000;

.randomStar(@color) {
  @x: `Math.floor(Math.random() * (@{max} - @{min})) `;
  @y: `Math.floor(Math.random() * (@{max} - @{min})) `;
  box-shadow+: unit(@x, px) unit(@y, px) @color;
}

.createStars(@n, @c) when (@n > 0) {
  .randomStar(@c);
  .createStars(@n - 1, @c);
}

@keyframes animateStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-3000px) translateX(5000px);
  }
}

.HeroStars {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.HeroStars1 {
  border-radius: 50%;
  background: transparent;
  width: 2px;
  height: 2px;
  .createStars(700, rgba(@color-teal-200, 0.45));
  animation: animateStar 700s linear infinite;
  &:after {
    content: "";
    left: -1500px;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    position: absolute;
    background: transparent;
    .createStars(700, rgba(@color-teal-200, 0.45));
  }
}

.HeroStars2 {
  border-radius: 50%;
  background: transparent;
  width: 3px;
  height: 3px;
  .createStars(500, rgba(@color-teal-200, 0.5));
  animation: animateStar 400s linear infinite;
  &:after {
    content: "";
    left: -1000px;
    width: 3.5px;
    height: 3.5px;
    border-radius: 50%;
    position: absolute;
    background: transparent;
    .createStars(500, rgba(@color-teal-200, 0.5));
  }
}

.HeroStars2 {
  border-radius: 50%;
  background: transparent;
  width: 5px;
  height: 5px;
  .createStars(50, rgba(@color-teal-100, 0.75));
  animation: animateStar 270s linear infinite;
  &:after {
    content: "";
    left: -1000px;
    width: 5.5px;
    height: 5.5px;
    border-radius: 50%;
    position: absolute;
    background: transparent;
    .createStars(50, rgba(@color-teal-100, 0.75));
  }
}
